<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'quotation-create'"
    v-if="getPermission('quotation:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title">
        <v-layout>
          <v-flex class="flex-grow-0"> Quote For </v-flex>
          <v-flex
            class="m-0 form-title-create-link pl-2 cursor-pointer"
            link
            :class="{ 'text-truncate': !lodash.isEmpty(customer) }"
            v-on:click="refCustomerDialog = true"
          >
            <template v-if="lodash.isEmpty(customer)">Client Name</template>
            <template v-else>{{ customer.display_name }}</template>
            <v-icon
              link
              large
              color="cyan"
              class="mx-2"
              v-if="lodash.isEmpty(customer)"
              >mdi-plus-circle-outline</v-icon
            >
          </v-flex>
        </v-layout>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack()"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        depressed
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate()"
      >
        Save Quotation
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="quotationForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate()"
      >
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow quotation-create-height"
          style="position: relative"
        >
          <div class="p-5 pt-0">
            <v-row>
              <v-col cols="12" v-if="getPermission('quotation:create')">
                <v-container fluid>
                  <v-card flat class="remove-border-radius">
                    <v-card-text ref="overview" class="p-6 font-size-16">
                      <v-row dense>
                        <v-col cols="7" class="pb-0">
                          <div>
                            <label class="font-weight-600 font-size-18 required"
                              >Quotation Title</label
                            >
                            <v-text-field
                              v-model.trim="quotationCreate.job_title"
                              dense
                              filled
                              label="Title"
                              solo
                              flat
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              color="cyan"
                              :rules="[
                                validateRules.required(
                                  quotationCreate.job_title,
                                  'Quotation Title'
                                ),
                                validateRules.minLength(
                                  quotationCreate.job_title,
                                  'Quotation Title',
                                  1
                                ),
                                validateRules.maxLength(
                                  quotationCreate.job_title,
                                  'Quotation Title',
                                  100
                                ),
                              ]"
                            ></v-text-field>
                            <v-textarea
                              v-model.trim="quotationCreate.description"
                              auto-grow
                              dense
                              filled
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              color="cyan"
                              label="Description"
                              solo
                              flat
                              :rules="[
                                validateRules.minLength(
                                  quotationCreate.description,
                                  'Description',
                                  1
                                ),
                                validateRules.maxLength(
                                  quotationCreate.description,
                                  'Description',
                                  1024
                                ),
                              ]"
                              row-height="25"
                            ></v-textarea>
                          </div>

                          <v-layout class="my-4">
                            <v-flex md6 class="mr-2 custom-border-right">
                              <table width="100%">
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Service Location</label>
                                    <v-icon
                                      v-if="!quotationId"
                                      class="ml-3"
                                      v-on:click="customerPropertyDialog = true"
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                    <v-icon
                                      class="ml-3"
                                      v-on:click="routeToServiceHistory()"
                                      color="cyan"
                                      small
                                      >mdi-history</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-0">
                                    <label>{{
                                      property.property_address
                                    }}</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Contact details</label>
                                    <v-chip
                                      v-if="property_contact_person.tenant"
                                      small
                                      color="green white--text"
                                      label
                                      class="ml-2 tenant-small-chip"
                                      >Tenant</v-chip
                                    >
                                    <v-icon
                                      v-if="!quotationId"
                                      class="ml-3"
                                      v-on:click="
                                        person_type = 'property';
                                        customerPersonDialog = true;
                                      "
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr v-if="property_contact_person.display_name">
                                  <td class="py-0">
                                    <label>{{
                                      property_contact_person.display_name
                                    }}</label>
                                  </td>
                                </tr>
                                <tr
                                  v-if="property_contact_person.primary_phone"
                                >
                                  <td class="py-0">
                                    <label>{{
                                      property_contact_person.primary_phone
                                    }}</label>
                                  </td>
                                </tr>
                                <tr
                                  v-if="property_contact_person.primary_email"
                                >
                                  <td class="py-0">
                                    <label>{{
                                      property_contact_person.primary_email
                                    }}</label>
                                  </td>
                                </tr>
                                <tr v-if="false && !quotationId">
                                  <td>
                                    <v-layout>
                                      <v-flex
                                        ><label class="font-weight-600">
                                          Email Notification
                                        </label></v-flex
                                      >
                                      <v-flex>
                                        <v-tooltip
                                          top
                                          content-class="custom-top-tooltip"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-switch
                                              class="m-0 p-0"
                                              color="cyan"
                                              dense
                                              v-model="
                                                quotationCreate.property_person_notify
                                              "
                                              inset
                                            ></v-switch>
                                          </template>
                                          <span
                                            v-if="
                                              quotationCreate.property_person_notify ==
                                              1
                                            "
                                            >Send Notification</span
                                          >
                                          <span v-else>No Notification</span>
                                        </v-tooltip>
                                      </v-flex>
                                    </v-layout>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="d-flex">
                                      <label class="font-weight-600">
                                        Delivery Site (Service Locaiton As
                                        Default)
                                      </label>

                                      <v-checkbox
                                        color="cyan"
                                        hide-details
                                        v-model="checkdeliverysite"
                                        class="m-0 p-0"
                                      ></v-checkbox>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="d-flex">
                                      <template v-if="checkdeliverysite">
                                        <v-textarea
                                          v-model.trim="
                                            quotationCreate.delivery_site
                                          "
                                          auto-grow
                                          dense
                                          filled
                                          :disabled="pageLoading"
                                          :loading="pageLoading"
                                          color="cyan"
                                          label="Delivery Site"
                                          solo
                                          flat
                                          row-height="25"
                                          :rules="[
                                            validateRules.required(
                                              quotationCreate.delivery_site,
                                              'Delivery Site'
                                            ),
                                            validateRules.minLength(
                                              quotationCreate.delivery_site,
                                              'Delivery Site',
                                              1
                                            ),
                                            validateRules.maxLength(
                                              quotationCreate.delivery_site,
                                              'Delivery Site',
                                              100
                                            ),
                                          ]"
                                        ></v-textarea>

                                        <!-- <v-text-field
                                          label="Delivery Site"
                                          dense
                                          filled
                                          solo
                                          flat
                                          :disabled="pageLoading"
                                          color="cyan"
                                          v-model="
                                            quotationCreate.delivery_site
                                          "
                                        ></v-text-field> -->
                                      </template>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </v-flex>
                            <v-flex md6 class="ml-2">
                              <table width="100%">
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Billing Address</label>
                                    <v-icon
                                      v-if="!quotationId"
                                      class="ml-3"
                                      v-on:click="customerBillingDialog = true"
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-0">
                                    <label>{{
                                      billing.property_address
                                    }}</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Contact details</label>
                                    <v-chip
                                      v-if="billing_contact_person.tenant"
                                      small
                                      color="green white--text"
                                      label
                                      class="ml-2 tenant-small-chip"
                                      >Tenant</v-chip
                                    >
                                    <v-icon
                                      v-if="!quotationId"
                                      class="ml-3"
                                      v-on:click="
                                        person_type = 'billing';
                                        customerPersonDialog = true;
                                      "
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr v-if="billing_contact_person.display_name">
                                  <td class="py-0">
                                    <label>{{
                                      billing_contact_person.display_name
                                    }}</label>
                                  </td>
                                </tr>
                                <tr v-if="billing_contact_person.primary_phone">
                                  <td class="py-0">
                                    <label>{{
                                      billing_contact_person.primary_phone
                                    }}</label>
                                  </td>
                                </tr>
                                <tr v-if="billing_contact_person.primary_email">
                                  <td class="py-0">
                                    <label>{{
                                      billing_contact_person.primary_email
                                    }}</label>
                                  </td>
                                </tr>
                                <tr v-if="false && !quotationId">
                                  <td>
                                    <v-layout>
                                      <v-flex
                                        ><label class="font-weight-600">
                                          Email Notification
                                        </label></v-flex
                                      >
                                      <v-flex>
                                        <v-tooltip
                                          top
                                          content-class="custom-top-tooltip"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-switch
                                              class="m-0 p-0"
                                              color="cyan"
                                              dense
                                              v-model="
                                                quotationCreate.billing_person_notify
                                              "
                                              inset
                                            ></v-switch>
                                          </template>
                                          <span
                                            v-if="
                                              quotationCreate.billing_person_notify ==
                                              1
                                            "
                                            >Send Notification</span
                                          >
                                          <span v-else>No Notification</span>
                                        </v-tooltip>
                                      </v-flex>
                                    </v-layout>
                                  </td>
                                </tr>
                              </table>
                            </v-flex>
                          </v-layout>
                        </v-col>
                        <v-col cols="5" class="pb-0">
                          <table width="100%">
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label>Quotation number</label>
                              </td>
                              <td class="font-weight-700">
                                <label>{{ quotationCreate.barcode }}</label>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label>Rate opportunity</label>
                              </td>
                              <td class="font-weight-700">
                                <v-rating
                                  :readonly="pageLoading"
                                  v-model.trim="quotationCreate.rating"
                                  background-color="orange lighten-3"
                                  color="orange"
                                ></v-rating>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="reference-no"
                                  >C/O or Customer Ref/Your Ref on PDF
                                  <!--Customer Reference # (For PDF as Your Ref.)--></label
                                >
                              </td>
                              <td>
                                <v-text-field
                                  id="reference-no"
                                  v-model.trim="quotationCreate.reference"
                                  dense
                                  filled
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  color="cyan"
                                  label="Customer Reference #"
                                  :rules="[
                                    validateRules.minLength(
                                      quotationCreate.reference,
                                      'Customer Reference',
                                      1
                                    ),
                                    validateRules.maxLength(
                                      quotationCreate.reference,
                                      'Customer Reference',
                                      100
                                    ),
                                  ]"
                                  solo
                                  flat
                                ></v-text-field>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="your-reference-no"
                                  >Internal Reference #</label
                                >
                              </td>
                              <td>
                                <v-text-field
                                  id="your-reference-no"
                                  v-model.trim="quotationCreate.your_reference"
                                  dense
                                  filled
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  color="cyan"
                                  label="Internal Reference #"
                                  :rules="[
                                    validateRules.minLength(
                                      quotationCreate.your_reference,
                                      'Internal Reference',
                                      1
                                    ),
                                    validateRules.maxLength(
                                      quotationCreate.your_reference,
                                      'Internal Reference',
                                      100
                                    ),
                                  ]"
                                  solo
                                  flat
                                ></v-text-field>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="sales-representatives"
                                  >Sales representatives</label
                                >
                              </td>
                              <td>
                                <v-autocomplete
                                  dense
                                  color="cyan"
                                  filled
                                  id="sales-representatives"
                                  :items="userList"
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  v-model.trim="quotationCreate.sales_executive"
                                  label="Sales representatives"
                                  solo
                                  flat
                                  item-color="cyan"
                                  item-text="display_name"
                                  item-value="id"
                                  hide-details
                                  v-on:change="updateSales()"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="
                                            'No Sales Representative Found.'
                                          "
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="prepared-by">Prepared by</label>
                              </td>
                              <td>
                                <v-autocomplete
                                  dense
                                  color="cyan"
                                  filled
                                  id="prepared-by"
                                  :items="userList"
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  v-model.trim="quotationCreate.prepared_by_id"
                                  label="Prepared by"
                                  solo
                                  flat
                                  item-color="cyan"
                                  item-text="display_name"
                                  item-value="id"
                                  hide-details
                                  v-on:change="updatePreparedBy()"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="'No User Found.'"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="currency">Currency</label>
                              </td>
                              <td>
                                <v-autocomplete
                                  dense
                                  color="cyan"
                                  filled
                                  id="currency"
                                  :items="currencyList"
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  v-model.trim="quotationCreate.currency"
                                  label="Currency"
                                  solo
                                  flat
                                  item-color="cyan"
                                  item-text="text"
                                  item-value="text"
                                  hide-details
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="'No Currency Found.'"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </td>
                            </tr>

                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="your-reference-no">FAX #</label>
                              </td>
                              <td>
                                <PhoneTextField
                                  id="fax"
                                  v-model.trim="quotationCreate.fax"
                                  :value="quotationCreate.fax"
                                  label="Fax"
                                >
                                </PhoneTextField>
                                <!-- <v-text-field
                                  id="fax"
                                  v-model.trim="quotationCreate.fax"
                                  dense
                                  filled
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  color="cyan"
                                  label="FAX #"
                                  type="number"
                                  :rules="[
                                    validateRules.minLength(
                                      quotationCreate.fax,
                                      'FAX',
                                      1
                                    ),
                                    validateRules.maxLength(
                                      quotationCreate.fax,
                                      'FAX',
                                      100
                                    ),
                                  ]"
                                  solo
                                  flat
                                ></v-text-field> -->
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="did">DID #</label>
                              </td>
                              <td>
                                <PhoneTextField
                                  id="did"
                                  v-model.trim="quotationCreate.did"
                                  :value="quotationCreate.did"
                                  label="DID"
                                >
                                </PhoneTextField>
                                <!-- <v-text-field
                                  id="did"
                                  v-model.trim="quotationCreate.did"
                                  dense
                                  filled
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  color="cyan"
                                  label="DID #"
                                  :rules="[
                                    validateRules.minLength(
                                      quotationCreate.did,
                                      'DID',
                                      1
                                    ),
                                    validateRules.maxLength(
                                      quotationCreate.did,
                                      'DID',
                                      100
                                    ),
                                  ]"
                                  solo
                                  flat
                                ></v-text-field> -->
                              </td>
                            </tr>

                            <template>
                              <tr v-if="!quotationId">
                                <td width="150" class="font-weight-600">
                                  <label>Is Contract ?</label>
                                </td>
                                <td valign="middle">
                                  <v-tooltip
                                    top
                                    content-class="custom-top-tooltip"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-switch
                                        class="m-0 my-2 p-0"
                                        color="cyan"
                                        dense
                                        v-on:change="enableContract($event)"
                                        inset
                                        hide-details
                                      ></v-switch>
                                    </template>
                                    <span>Contract ?</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                              <template v-if="quotationCreate.is_contract">
                                <tr>
                                  <td width="150" class="font-weight-600">
                                    <label for="duration-value">Duration</label>
                                  </td>
                                  <td>
                                    <v-layout>
                                      <v-flex md6>
                                        <v-text-field
                                          dense
                                          filled
                                          id="duration-value"
                                          color="cyan"
                                          :disabled="pageLoading"
                                          :loading="pageLoading"
                                          v-mask="'##'"
                                          v-model.trim="
                                            quotationCreate.duration_value
                                          "
                                          label="Duration"
                                          solo
                                          flat
                                          hide-details
                                          v-on:keyup="updateDuration()"
                                        ></v-text-field>
                                      </v-flex>
                                      <v-flex md6>
                                        <v-select
                                          dense
                                          color="cyan"
                                          filled
                                          id="duration-type"
                                          :items="durationTypeList"
                                          :disabled="pageLoading"
                                          :loading="pageLoading"
                                          v-model.trim="
                                            quotationCreate.duration_type
                                          "
                                          v-on:change="updateDuration()"
                                          label="Duration"
                                          solo
                                          flat
                                          item-color="cyan"
                                          hide-details
                                        >
                                          <template v-slot:no-data>
                                            <v-list-item>
                                              <v-list-item-content>
                                                <v-list-item-title
                                                  v-html="
                                                    'No Duration Type Found.'
                                                  "
                                                ></v-list-item-title>
                                              </v-list-item-content>
                                            </v-list-item>
                                          </template>
                                        </v-select>
                                      </v-flex>
                                    </v-layout>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="150" class="font-weight-600">
                                    <label for="start-date-picker">Dates</label>
                                  </td>
                                  <td>
                                    <v-layout>
                                      <v-flex md6
                                        ><DatePicker
                                          solo
                                          flat
                                          :default-date="
                                            quotationCreate.contract_start
                                          "
                                          v-model="
                                            quotationCreate.contract_start
                                          "
                                        ></DatePicker
                                      ></v-flex>
                                      <v-flex md6
                                        ><DatePicker
                                          solo
                                          flat
                                          :default-date="
                                            quotationCreate.contract_end
                                          "
                                          v-model="quotationCreate.contract_end"
                                        ></DatePicker
                                      ></v-flex>
                                    </v-layout>
                                  </td>
                                </tr>
                                <tr v-if="false">
                                  <td width="150" class="font-weight-600">
                                    <label for="duration-picker"
                                      >Duration</label
                                    >
                                  </td>
                                  <td>
                                    <DateRangePicker
                                      prop_id="duration-picker"
                                      :page-loading="pageLoading"
                                      prop_label="Duration"
                                      :prop_dates="quotationCreate.duration"
                                      v-model="quotationCreate.duration"
                                    ></DateRangePicker>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="150" class="font-weight-600">
                                    <label for="no-of-services"
                                      >No. of Services</label
                                    >
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      filled
                                      id="no-of-services"
                                      color="cyan"
                                      :disabled="pageLoading"
                                      :loading="pageLoading"
                                      v-mask="'####'"
                                      :rules="[
                                        validateRules.required(
                                          quotationCreate.total_service,
                                          'No. of Services'
                                        ),
                                        validateRules.minLength(
                                          quotationCreate.total_service,
                                          'No. of Services',
                                          1
                                        ),
                                        validateRules.maxLength(
                                          quotationCreate.total_service,
                                          'No. of Services',
                                          100
                                        ),
                                      ]"
                                      v-model.trim="
                                        quotationCreate.total_service
                                      "
                                      label="No. of Services"
                                      solo
                                      flat
                                      hide-details
                                    ></v-text-field>
                                  </td>
                                </tr>
                              </template>
                            </template>
                          </table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-col>
              <v-col cols="12" v-if="getPermission('line-item:create')">
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Line Items
                      </h3>
                    </v-card-title>
                    <v-card-text ref="lineItem" class="p-6 font-size-16">
                      <v-row dense>
                        <v-col cols="12" class="pt-0 pb-0">
                          <QuoteLineItemNew
                            is-quotation
                            can-update
                            :entity-id="entityId"
                            :property-id="quotationProperty"
                            :currency="quotationCreate.currency"
                            :exchange-rate="exchange_rate"
                            :db-line-items="dbLineItems"
                            :db-equipments="dbEquipments"
                            :discount-value="quotationCreate.discount_value"
                            :discount-value-type="
                              quotationCreate.discount_value_type
                            "
                            :apply-tax="quotationCreate.tax_applied"
                            :adjustment-value="quotationCreate.adjustment"
                            v-on:update:equipment="updateEquipment($event)"
                            v-on:update:line-item="updateLineItem($event)"
                            v-on:update:line-item-calculation="
                              updateLineItemCalculation($event)
                            "
                          ></QuoteLineItemNew>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-col>
              <v-col cols="12">
                <QuoteNotesAttachment
                  :update-data="updateData"
                  :page-loading="pageLoading"
                ></QuoteNotesAttachment>
              </v-col>
              <v-col cols="12">
                <QuoteTermsConditions
                  :update-data="updateData"
                  :page-loading="pageLoading"
                  :quotationId="quotationId"
                ></QuoteTermsConditions>
              </v-col>
            </v-row>
          </div>
        </perfect-scrollbar>
      </v-form>

      <template v-if="refEntityId && refCustomerDialog">
        <RefCustomerDialog
          :entity="refEntityId"
          :customer-dialog="refCustomerDialog"
          v-on:closeDialog="refCloseDialog"
          v-on:resetAll="refResetAll"
          v-on:selectCustomer="refSelectCustomer"
        ></RefCustomerDialog>
      </template>
      <template v-if="refCustomerPropertyDialog">
        <RefCustomerPropertyDialog
          disabled-auto-select
          :customer-property-dialog="refCustomerPropertyDialog"
          :customer="refCustomerId"
          v-on:closeDialog="refCloseDialog"
          v-on:resetAll="refResetAll"
          v-on:selectCustomerProperty="refSelectCustomerProperty"
        ></RefCustomerPropertyDialog>
      </template>

      <template v-if="customerPersonDialog">
        <CustomerPersonDialog
          disabled-auto-select
          :customerPersonDialog="customerPersonDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerPerson="selectCustomerPerson"
        ></CustomerPersonDialog>
      </template>
      <template v-if="customerPropertyDialog">
        <CustomerPropertyDialog
          disabled-auto-select
          :customerPropertyDialog="customerPropertyDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerProperty="selectCustomerProperty"
        ></CustomerPropertyDialog>
      </template>
      <template v-if="customerBillingDialog">
        <CustomerBillingDialog
          disabled-auto-select
          :customerBillingDialog="customerBillingDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerBilling="selectCustomerBilling"
        ></CustomerBillingDialog>
      </template>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import { SET_CC_PERSONS } from "@/core/services/store/common.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import { QUERY, POST, PUT } from "@/core/services/store/request.module";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import CustomerBillingDialog from "@/view/pages/partials/Select-Customer-Billing.vue";
import DateRangePicker from "@/view/pages/partials/DateRangePicker.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import QuoteLineItemNew from "@/view/pages/partials/Line-Item-New.vue";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import {
  TermConditionEventBus,
  TermConditionOptionsEventBus,
} from "@/core/lib/term.condition.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import QuoteTermsConditions from "@/view/pages/partials/Quote-Terms-Conditions.vue";
import QuoteNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";

import RefCustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import RefCustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";

import {
  concat,
  compact,
  filter,
  isEmpty,
  toSafeInteger,
  map,
  find,
} from "lodash";
import moment from "moment-timezone";
import ObjectPath from "object-path";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  name: "quotation-create",
  title: "Create Quotation",
  data() {
    return {
      refEntityId: 1,
      refCustomerDialog: false,
      refCustomerPropertyDialog: false,
      isDupChanged: 0,
      refCustomerId: 0,
      refPropertyId: 0,

      formLoading: false,
      pageLoading: false,
      quotationCreated: false,
      checkdeliverysite: false,
      lineItemCreated: false,
      customer: {},
      property: {},
      billing: {},
      exchange_rate: 1,
      barcodeSetting: {},
      entityId: 1,
      quotationCreate: {
        fax: null,
        did: null,
        delivery_site: null,
        job_title: null,
        description: null,
        property_person_notify: 1,
        billing_person_notify: 1,
        barcode: null,
        rating: 1,
        reference: null,
        your_reference: null,
        sales_id: null,
        prepared_by_id: null,
        sales_executive: null,
        prepared_by: null,
        is_contract: null,
        duration_value: 1,
        duration_type: "year",
        contract_start: moment().format("YYYY-MM-DD"),
        contract_end: moment().add(1, "year").format("YYYY-MM-DD"),
        duration: null,
        total_service: null,
        discount_value: null,
        discount_value_type: null,
        tax_applied: null,
        adjustment: null,
        sales: null,
        currency: "SGD",
        terms: {
          quote: null,
          validity: null,
          delivery: null,
          warranty: null,
          payment: null,
          gst_charges: null,
          deposit: null,
          cancellation: null,
          stock_availability: null,
        },
      },
      terms: {},
      durationTypeList: [
        { text: "Year", value: "year" },
        { text: "Month", value: "month" },
      ],
      currencyList: [
        { text: "SGD", value: "SGD" },
        { text: "USD", value: "USD" },
      ],
      customerPersonDialog: false,
      customerBillingDialog: false,
      customerPropertyDialog: false,
      customerDialog: false,
      customerId: 0,
      customerPerson: 0,
      customerProperty: 0,
      customerBilling: 0,
      person_type: null,
      ticketId: null,
      visitId: null,
      invoiceId: null,
      quotationId: null,
      property_contact_person: {},
      billing_contact_person: {},
      userList: [],
      lineItem: [],
      equipments: [],
      dbLineItems: [],
      dbEquipments: [],
      noteAttachment: {},
      termsCondition: null,

      lineItemCalculation: {
        taxApplied: 0,
        discountValue: 0,
        adjustmentAmount: 0,
        discountType: 1,
        discountValueType: 0,
      },
      reviseQuotation: 0,
      updateData: {
        term_conditions: null,
        admin_remark: null,
        client_remark: null,
        notify_admin: 0,
        notify_customer: 0,
        notify_engineer: 0,
        documents: [],
        terms: {},
      },
    };
  },
  components: {
    DatePicker,
    DateRangePicker,
    RefCustomerDialog,
    RefCustomerPropertyDialog,
    CustomerPersonDialog,
    CustomerPropertyDialog,
    CustomerBillingDialog,
    QuoteLineItemNew,
    CreateUpdateTemplate,
    QuoteTermsConditions,
    QuoteNotesAttachment,
    PhoneTextField,
  },
  methods: {
    refCloseDialog() {
      this.refCustomerDialog = false;
      this.refCustomerPropertyDialog = false;
    },
    refResetAll() {
      this.refEntityId = 1;
      this.refCustomerDialog = false;
      this.refCustomerPropertyDialog = false;
      this.refCustomerId = 0;
    },
    refSelectCustomer(param) {
      this.refCustomerId = param;
      this.$nextTick(() => {
        this.refCustomerDialog = false;
        this.refCustomerPropertyDialog = true;
      });
    },
    refSelectCustomerProperty(param) {
      this.refPropertyId = param;
      let route = this.getDefaultRoute("quotation.create", {
        query: {
          customer: this.refCustomerId,
          property: this.refPropertyId,
          duplicate: ObjectPath.get(this.$route, "query.duplicate"),
          isDupChanged: 1,
        },
      });
      const resolved = this.$router.resolve(route);
      window.location.href = resolved.href;
    },
    resetAll() {
      this.entity = 1;
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    selectCustomer(param) {
      this.customerId = param;
      this.closeDialog();
      /*if (!this.quotationProperty || this.quotationProperty <= 0) {
        this.customerPropertyDialog = true;
      }*/
    },
    enableContract(param) {
      if (param) {
        this.quotationCreate.is_contract = true;
        this.quotationCreate.duration_value = 1;
        this.quotationCreate.duration_type = "year";
        this.quotationCreate.contract_start = moment().format("YYYY-MM-DD");
        this.quotationCreate.contract_end = moment()
          .add(1, "year")
          .format("YYYY-MM-DD");
        this.quotationCreate.total_service = null;
      } else {
        this.quotationCreate.is_contract = false;
        this.quotationCreate.duration_value = 0;
        this.quotationCreate.duration_type = null;
        this.quotationCreate.contract_start = null;
        this.quotationCreate.contract_end = null;
        this.quotationCreate.total_service = null;
      }
    },
    updateDuration() {
      const { contract_start, duration_value, duration_type } =
        this.quotationCreate;
      this.quotationCreate.contract_end = moment(contract_start)
        .add(duration_value, duration_type)
        .format("YYYY-MM-DD");
    },
    updateLineItem(rows) {
      this.lineItem = rows;
    },
    updateEquipment(rows) {
      this.equipments = rows;
    },
    updateLineItemCalculation(row) {
      this.lineItemCalculation.taxApplied = row.apply_tax;
      this.lineItemCalculation.discountValue = row.discount_value;
      this.lineItemCalculation.adjustmentAmount = row.adjustment;
      this.lineItemCalculation.discountType = 1;
      this.lineItemCalculation.discountValueType = row.discount_value_type;
    },
    updateSales() {
      const sales_executive = find(this.userList, {
        id: this.quotationCreate.sales_executive,
      });
      if (sales_executive) {
        this.quotationCreate.sales = sales_executive.display_name;
      }
    },
    updatePreparedBy() {
      const prepared_by = find(this.userList, {
        id: this.quotationCreate.prepared_by_id,
      });
      if (prepared_by) {
        this.quotationCreate.prepared_by = prepared_by.display_name;
      }
    },

    routeToServiceHistory() {
      this.forcePush = true;
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("property.detail", {
            params: { id: this.property.id },
            query: {
              tab: "property-history",
            },
          })
        );
      });
    },
    closeDialog() {
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerBillingDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomerPerson(param) {
      if (this.person_type == "property") {
        if (this.quotationPropertyPerson == param) {
          this.closeDialog();
          return false;
        }
        this.quotationPropertyPerson = param;
      }
      if (this.person_type == "billing") {
        if (this.quotationBillingPerson == param) {
          this.closeDialog();
          return false;
        }
        this.quotationBillingPerson = param;
      }
      this.closeDialog();
      this.pushToRouteQuotation();
    },
    selectCustomerProperty(param) {
      if (this.quotationProperty == param) {
        this.closeDialog();
        return false;
      }
      this.quotationProperty = param;
      this.closeDialog();
      this.pushToRouteQuotation();
    },
    selectCustomerBilling(param) {
      if (this.quotationBilling == param) {
        this.closeDialog();
        return false;
      }
      this.quotationBilling = param;
      this.closeDialog();
      this.pushToRouteQuotation();
    },
    pushToRouteQuotation() {
      this.$router
        .replace(
          this.getDefaultRoute("quotation.create", {
            query: {
              customer: this.quotationCustomer,
              billing: this.quotationBilling,
              property: this.quotationProperty,
              property_person: this.quotationPropertyPerson,
              billing_person: this.quotationBillingPerson,
              duplicate: this.duplicateQuotation,
              revise: this.reviseQuotation,
              ticket: this.ticketId,
              visit: this.visitId,
            },
          })
        )
        .then(() => {
          this.getOptions();
        });
    },
    async updateOrCreate(type) {
      const _this = this;

      const validateStatus = _this.$refs.quotationForm.validate();

      const formErrors = _this.validateForm(_this.$refs.quotationForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      const line_items = concat(_this.equipments, _this.lineItem);

      let validateLineItem = compact(
        map(line_items, function (row) {
          return row.product_id;
        })
      );

      if (isEmpty(validateLineItem)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select product/service then try again.")
        );
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      let REQUEST_TYPE = POST;
      let REQUEST_URL = "quotation";

      if (_this.quotationId && _this.quotationId > 0) {
        REQUEST_TYPE = PUT;
        REQUEST_URL = "quotation/" + _this.quotationId;
      }

      if (_this.reviseQuotation && _this.reviseQuotation > 0) {
        type = "revise";
        REQUEST_TYPE = PUT;
        REQUEST_URL = "quotation/" + _this.reviseQuotation;
      }

      const formData = new Object({
        action: typeof type == "string" ? type : undefined,
        ticket: _this.ticketId,
        visit: _this.visitId,
        invoice: _this.invoiceId,
        customer: _this.quotationCustomer,
        billing: _this.quotationBilling,
        property: _this.quotationProperty,
        property_person: _this.quotationPropertyPerson,
        billing_person: _this.quotationBillingPerson,
        job_title: _this.quotationCreate.job_title,
        delivery_site: _this.checkdeliverysite
          ? _this.quotationCreate.delivery_site
          : "",
        currency: _this.quotationCreate.currency,
        description: _this.quotationCreate.description,
        property_person_notify: toSafeInteger(
          _this.quotationCreate.property_person_notify
        ),
        billing_person_notify: toSafeInteger(
          _this.quotationCreate.billing_person_notify
        ),
        duration_value: _this.quotationCreate.duration_value,
        duration_type: _this.quotationCreate.duration_type,
        contract_start: _this.quotationCreate.contract_start,
        contract_end: _this.quotationCreate.contract_end,
        barcode: _this.quotationCreate.barcode,
        reference: _this.quotationCreate.reference,
        your_reference: _this.quotationCreate.your_reference,
        rating: _this.quotationCreate.rating,
        prepared_by_id: _this.quotationCreate.prepared_by_id,
        sales_executive: _this.quotationCreate.sales_executive,
        prepared_by: _this.quotationCreate.prepared_by,
        is_contract: toSafeInteger(_this.quotationCreate.is_contract),
        duration: _this.quotationCreate.duration || [],
        total_service: toSafeInteger(_this.quotationCreate.total_service),
        sales: _this.quotationCreate.sales,
        documents: _this.noteAttachment.documents,
        admin_remark: _this.noteAttachment.admin_notes,
        client_remark: _this.noteAttachment.client_notes,
        term_conditions: _this.termsCondition,
        tax_applied: _this.lineItemCalculation.taxApplied,
        discount_value: _this.lineItemCalculation.discountValue,
        adjustment: _this.lineItemCalculation.adjustmentAmount,
        discount_type: _this.lineItemCalculation.discountType,
        discount_value_type: _this.lineItemCalculation.discountValueType,
        quote_terms: _this.terms,
        fax: _this.quotationCreate.fax,
        did: _this.quotationCreate.did,
      });

      /*  console.log(_this.terms,"hello");  
      console.log(formData,"formData");  
      
      let status = true;  
      if(status){  
        return false; 
      } */
      _this.formLoading = true;
      if (!_this.quotationCreated) {
        try {
          const quotation = await _this.$store.dispatch(REQUEST_TYPE, {
            url: REQUEST_URL,
            data: formData,
          });

          if (!isEmpty(quotation)) {
            _this.quotationId = toSafeInteger(quotation.data.id);
            _this.quotationCreated = true;
          }
        } catch (error) {
          _this.logError(error);
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Quotation is not created. Please try again.")
          );
          return false;
        }
      }

      if (
        !_this.lineItemCreated &&
        _this.quotationCreated &&
        _this.quotationId
      ) {
        try {
          await _this.CreateLineItems({
            type: "quotation",
            parent: _this.quotationId,
            formData: line_items,
          });

          _this.lineItemCreated = true;
        } catch (error) {
          _this.logError(error);
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Line items are not created. Please try again.")
          );
          return false;
        }
      }

      _this.formLoading = false;

      _this.$nextTick(() => {
        _this.$router.push(
          _this.getDefaultRoute("quotation", {
            query: {
              status: "all",
            },
          })
        );
      });
    },
    getLineItems(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(QUERY, {
            url: "line-item/new",
            data,
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setUpdateData(param) {
      const _this = this;

      _this.quotationCreate.job_title = param.job_title;
      _this.quotationCreate.delivery_site = param.delivery_site;
      _this.quotationCreate.description = param.description;
      _this.quotationCreate.property_person_notify =
        param.property_person_notify;
      _this.quotationCreate.billing_person_notify = param.billing_person_notify;
      _this.quotationCreate.rating = param.rating;
      _this.quotationCreate.reference = param.reference;
      _this.quotationCreate.your_reference = param.your_reference;
      _this.quotationCreate.prepared_by = param.prepared_by;
      _this.quotationCreate.sales = param.sales;
      _this.quotationCreate.prepared_by_id = param.prepared_by_id;
      _this.quotationCreate.sales_executive = param.sales_id;
      _this.quotationCreate.duration_value = param.duration_value;
      _this.quotationCreate.duration_type = param.duration_type;
      _this.quotationCreate.contract_start = param.contract_start;
      _this.quotationCreate.contract_end = param.contract_end;
      _this.quotationCreate.is_contract = param.is_contract;
      _this.quotationCreate.duration = param.duration;
      _this.quotationCreate.total_service = param.total_service;
      _this.quotationCreate.discount_value = param.discount_value;
      _this.quotationCreate.discount_value_type = param.discount_value_type;
      _this.quotationCreate.tax_applied = param.tax_applied;
      _this.quotationCreate.adjustment = param.adjustment;
      _this.quotationCreate.currency = param.currency;
      _this.checkdeliverysite = param.delivery_site ? true : false;

      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = param.documents;
      _this.quotationCreate.fax = param.fax;
      _this.quotationCreate.did = param.did;

      _this.updateData.terms = {
        validity: param.validity,
        cancellation: param.cancellation,
        delivery: param.delivery,
        deposit: param.deposit,
        gst_charges: param.gst_charges,
        payment: param.payment,
        stock_availability: param.stock_availability,
        warranty: param.warranty,
      };

      _this
        .getLineItems({
          quotation: _this.quotationId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: data[i].id,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              threshold_price: data[i].threshold_price * this.exchange_rate,
              project_price: data[i] ? data[i].project_price : 0,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
              is_optional: data[i].is_optional,
              order: data[i].order,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setReviseData(param) {
      const _this = this;

      _this.quotationCreate.job_title = param.job_title;
      _this.quotationCreate.description = param.description;
      _this.quotationCreate.property_person_notify =
        param.property_person_notify;
      _this.quotationCreate.billing_person_notify = param.billing_person_notify;
      _this.quotationCreate.rating = param.rating;
      _this.quotationCreate.reference = param.reference;
      _this.quotationCreate.your_reference = param.your_reference;
      _this.quotationCreate.prepared_by = param.prepared_by;
      _this.quotationCreate.sales = param.sales;
      _this.quotationCreate.prepared_by_id = param.prepared_by_id;
      _this.quotationCreate.sales_executive = param.sales_id;
      _this.quotationCreate.duration_value = param.duration_value;
      _this.quotationCreate.duration_type = param.duration_type;
      _this.quotationCreate.contract_start = param.contract_start;
      _this.quotationCreate.contract_end = param.contract_end;
      _this.quotationCreate.is_contract = param.is_contract;
      _this.quotationCreate.duration = param.duration;
      _this.quotationCreate.total_service = param.total_service;
      _this.quotationCreate.discount_value = param.discount_value;
      _this.quotationCreate.discount_value_type = param.discount_value_type;
      _this.quotationCreate.tax_applied = param.tax_applied;
      _this.quotationCreate.adjustment = param.adjustment;
      _this.quotationCreate.sales = param.sales;
      _this.checkdeliverysite = param.delivery_site ? true : false;
      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.quotationCreate.fax = param.fax;
      _this.quotationCreate.did = param.did;
      _this.updateData.documents = [];

      _this
        .getLineItems({
          quotation: _this.reviseQuotation,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              is_optional: data[i].is_optional,
              order: data[i].order,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              threshold_price: data[i].threshold_price,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setDuplicateData(param) {
      const _this = this;

      _this.quotationCreate.job_title = param.job_title;
      _this.quotationCreate.delivery_site = param.delivery_site;
      _this.quotationCreate.description = param.description;
      _this.quotationCreate.property_person_notify =
        param.property_person_notify;
      _this.quotationCreate.billing_person_notify = param.billing_person_notify;
      _this.quotationCreate.rating = param.rating;
      _this.quotationCreate.reference = null; //`Quotation # ${param.barcode}`;
      _this.quotationCreate.your_reference = param.your_reference;
      _this.quotationCreate.prepared_by = param.prepared_by;
      _this.quotationCreate.sales = param.sales;
      _this.quotationCreate.prepared_by_id = param.prepared_by_id;
      _this.quotationCreate.sales_executive = param.sales_id;
      _this.quotationCreate.duration_value = param.duration_value;
      _this.quotationCreate.duration_type = param.duration_type;
      _this.quotationCreate.contract_start = param.contract_start;
      _this.quotationCreate.contract_end = param.contract_end;
      _this.quotationCreate.is_contract = param.is_contract;
      _this.quotationCreate.duration = param.duration;
      _this.quotationCreate.total_service = param.total_service;
      _this.quotationCreate.discount_value = param.discount_value;
      _this.quotationCreate.discount_value_type = param.discount_value_type;
      _this.quotationCreate.tax_applied = param.tax_applied;
      _this.quotationCreate.adjustment = param.adjustment;
      _this.quotationCreate.sales = param.sales;
      _this.checkdeliverysite = param.delivery_site ? true : false;
      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      if (parseInt(param.customer) == parseInt(this.quotationCustomer)) {
        _this.quotationCreate.fax = param.fax;
        _this.quotationCreate.did = param.did;
      }
      _this.updateData.documents = [];

      /* const sales_executive = find(_this.userList, {
        full_name: param.sales,
      });
      if (sales_executive) {
        _this.quotationCreate.sales = sales_executive.full_name;
        _this.quotationCreate.sales_executive = sales_executive.id;
      } */

      _this
        .getLineItems({
          quotation: _this.duplicateQuotation,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              threshold_price: data[i].threshold_price,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
              is_optional: data[i].is_optional,
              order: data[i].order,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setTicketData(param) {
      const _this = this;

      _this.quotationCreate.job_title = param.title;
      _this.quotationCreate.description = param.description;
      _this.quotationCreate.property_person_notify =
        param.property_person_notify;
      _this.quotationCreate.billing_person_notify = param.billing_person_notify;
      _this.quotationCreate.reference = `Visit # ${param.barcode}`;
      _this.quotationCreate.discount_value = param.discount_value;
      _this.quotationCreate.discount_value_type = param.discount_value_type;
      _this.quotationCreate.tax_applied = param.tax_applied;
      _this.quotationCreate.adjustment = param.adjustment;

      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = [];

      _this
        .getLineItems({
          job: _this.ticketId,
          visit: _this.visitId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              threshold_price: data[i].threshold_price,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setInvoiceData(param) {
      const _this = this;

      _this.quotationCreate.job_title = param.title;
      _this.quotationCreate.property_person_notify =
        param.property_person_notify;
      _this.quotationCreate.billing_person_notify = param.billing_person_notify;
      _this.quotationCreate.reference = `Invoice # ${param.barcode}`;
      _this.quotationCreate.discount_value = param.discount_value;
      _this.quotationCreate.discount_value_type = param.discount_value_type;
      _this.quotationCreate.tax_applied = param.tax_applied;
      _this.quotationCreate.adjustment = param.adjustment;

      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = [];

      _this
        .getLineItems({
          invoice: _this.invoiceId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              threshold_price: data[i].threshold_price,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getOptions() {
      this.pageLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: "quotation/options",
          data: {
            duplicate: this.duplicateQuotation,
            revise: this.reviseQuotation,
            quotation: this.quotationId,
            invoice: this.invoiceId,
            ticket: this.ticketId,
            visit: this.visitId,
            customer: this.quotationCustomer,
            billing: this.quotationBilling,
            property: this.quotationProperty,
            is_dup_changed: this.isDupChanged,
            property_person: this.quotationPropertyPerson,
            billing_person: this.quotationBillingPerson,
          },
        })
        .then(({ data }) => {
          this.quotationCreate.delivery_site = data.property.property_address;
          this.exchange_rate = data.exchange_rate;
          this.updateData.term_conditions = data.options.terms_conditions;
          this.updateData.admin_remark = data.options.client_note;
          this.updateData.client_remark = data.options.admin_note;
          if (data.user && data.user.display_name) {
            this.quotationCreate.prepared_by = data.user.display_name;
            this.quotationCreate.prepared_by_id = data.user.id;
          }

          if (data.customer && data.customer.contact_person) {
            this.$store.dispatch(SET_CC_PERSONS, data.customer.contact_person);
          }

          if (data.barcode) {
            this.quotationCreate.barcode = data.barcode;
          }

          if (!isEmpty(data.customer_fax)) {
            this.quotationCreate.fax = data.customer_fax.value;
          }

          /* if (!isEmpty(data.customer_did)) {
            this.quotationCreate.did = data.customer_did.value;
          } */

          if (!isEmpty(data.property_person)) {
            this.quotationCreate.did = data.property_person.did;
          }

          if (!isEmpty(data.sales_users)) {
            this.userList = data.sales_users;
          }

          if (!isEmpty(data.options)) {
            this.barcodeSetting = data.options;
            this.updateData.term_conditions =
              this.barcodeSetting.terms_conditions;
          }

          if (!isEmpty(data.quotation)) {
            this.setUpdateData(data.quotation);
          }

          if (!isEmpty(data.revise)) {
            this.setReviseData(data.revise);
          }

          if (!isEmpty(data.invoice)) {
            this.setInvoiceData(data.invoice);
          }

          if (!isEmpty(data.ticket)) {
            this.setTicketData(data.ticket);
          }

          if (!isEmpty(data.duplicate)) {
            this.setDuplicateData(data.duplicate);
          }

          if (!isEmpty(data.customer)) {
            this.customer = data.customer;
            this.quotationCustomer = this.customer.id;
            this.entityId = data.customer.entity_id;
          }

          if (!isEmpty(data.billing)) {
            this.billing = data.billing;
            this.quotationBilling = this.billing.id;
          }

          if (!isEmpty(data.property)) {
            this.property = data.property;
            this.quotationProperty = this.property.id;
          }

          if (!isEmpty(data.property_person)) {
            this.property_contact_person = data.property_person;
            this.quotationPropertyPerson = this.property_contact_person.id;
          }

          if (!isEmpty(data.billing_person)) {
            this.billing_contact_person = data.billing_person;
            this.quotationBillingPerson = this.billing_contact_person.id;
          }

          /* this.quotationCreate.sales_executive = ObjectPath.get(
            data,
            "user.id",
            0
          );
          this.quotationCreate.prepared_by_id = ObjectPath.get(
            data,
            "user.id",
            0
          ); */

          this.$nextTick(() => {
            this.updateSales();
            this.updatePreparedBy();
          });
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getOptions();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quotation", route: "quotation" },
      { title: "Create" },
    ]);

    TermConditionEventBus.$on("update:term-condition", (argument) => {
      this.termsCondition = argument;
    });

    TermConditionOptionsEventBus.$on(
      "update:term-terms-options",
      (argument) => {
        this.terms = argument;
      }
    );

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      this.noteAttachment = argument;
    });
  },
  beforeMount() {
    this.quotationId = toSafeInteger(this.$route.params.id);
    this.quotationCustomer = toSafeInteger(this.$route.query.customer);
    this.quotationBilling = toSafeInteger(this.$route.query.billing);
    this.quotationProperty = toSafeInteger(this.$route.query.property);
    this.quotationPropertyPerson = toSafeInteger(
      this.$route.query.property_person
    );
    this.quotationBillingPerson = toSafeInteger(
      this.$route.query.billing_person
    );
    this.duplicateQuotation = toSafeInteger(this.$route.query.duplicate);
    this.reviseQuotation = toSafeInteger(this.$route.query.revise);
    this.ticketId = toSafeInteger(this.$route.query.ticket);
    this.visitId = toSafeInteger(this.$route.query.visit);
    this.invoiceId = toSafeInteger(this.$route.query.invoice);
    this.isDupChanged = toSafeInteger(this.$route.query.isDupChanged);
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } else if (_this.quotationCreated && _this.lineItemCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
