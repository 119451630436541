var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"custom-grey-border remove-border-radius",attrs:{"flat":"","disabled":_vm.pageLoading}},[_c('v-card-title',{staticClass:"headline grey lighten-4"},[_c('h3',{staticClass:"font-weight-700 custom-headline color-custom-blue"},[_vm._v(" Terms & Conditions ")])]),_c('v-card-text',{staticClass:"p-6 font-size-16"},[_c('div',{staticClass:"d-flex w-50"},[_c('v-autocomplete',{attrs:{"dense":"","color":"cyan","filled":"","id":"select-quote","items":_vm.quoteList,"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"label":"Select Terms & Conditions","solo":"","flat":"","item-color":"cyan","item-text":"title","item-value":"id","hide-details":""},on:{"change":function($event){return _vm.selectQuote()}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('No Quote Found.')}})],1)],1)]},proxy:true}]),model:{value:(_vm.terms.quote),callback:function ($$v) {_vm.$set(_vm.terms, "quote", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"terms.quote"}})],1),_c('br'),_c('div',{staticClass:"d-flex"},[_c('v-textarea',{attrs:{"auto-grow":"","dense":"","filled":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","label":"Validity","solo":"","flat":"","rules":[
            _vm.validateRules.minLength(_vm.terms.description, 'Validity', 1),
            _vm.validateRules.maxLength(_vm.terms.description, 'Validity', 1024),
          ],"row-height":"25"},model:{value:(_vm.terms.validity),callback:function ($$v) {_vm.$set(_vm.terms, "validity", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"terms.validity"}}),_c('v-textarea',{attrs:{"auto-grow":"","dense":"","filled":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","label":"Payment","solo":"","flat":"","rules":[
            _vm.validateRules.minLength(_vm.terms.payment, 'Payment', 1),
            _vm.validateRules.maxLength(_vm.terms.payment, 'Payment', 1024),
          ],"row-height":"25"},model:{value:(_vm.terms.payment),callback:function ($$v) {_vm.$set(_vm.terms, "payment", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"terms.payment"}})],1),_c('div',{staticClass:"d-flex"},[_c('v-textarea',{attrs:{"auto-grow":"","dense":"","filled":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","label":"Delivery","solo":"","flat":"","rules":[
            _vm.validateRules.minLength(_vm.terms.delivery, 'Delivery', 1),
            _vm.validateRules.maxLength(_vm.terms.delivery, 'Delivery', 1024),
          ],"row-height":"25"},model:{value:(_vm.terms.delivery),callback:function ($$v) {_vm.$set(_vm.terms, "delivery", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"terms.delivery"}}),_c('v-textarea',{attrs:{"auto-grow":"","dense":"","filled":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","label":"Deposit","solo":"","flat":"","rules":[
            _vm.validateRules.minLength(_vm.terms.deposit, 'Deposit', 1),
            _vm.validateRules.maxLength(_vm.terms.deposit, 'Deposit', 1024),
          ],"row-height":"25"},model:{value:(_vm.terms.deposit),callback:function ($$v) {_vm.$set(_vm.terms, "deposit", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"terms.deposit"}})],1),_c('div',{staticClass:"d-flex"},[_c('v-textarea',{attrs:{"auto-grow":"","dense":"","filled":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","label":"Warranty","solo":"","flat":"","rules":[
            _vm.validateRules.minLength(_vm.terms.warranty, 'Warranty', 1),
            _vm.validateRules.maxLength(_vm.terms.warranty, 'Warranty', 1024),
          ],"row-height":"25"},model:{value:(_vm.terms.warranty),callback:function ($$v) {_vm.$set(_vm.terms, "warranty", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"terms.warranty"}}),_c('v-textarea',{attrs:{"auto-grow":"","dense":"","filled":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","label":"Cancellation","solo":"","flat":"","rules":[
            _vm.validateRules.minLength(_vm.terms.cancellation, 'Cancellation', 1),
            _vm.validateRules.maxLength(_vm.terms.cancellation, 'Cancellation', 1024),
          ],"row-height":"25"},model:{value:(_vm.terms.cancellation),callback:function ($$v) {_vm.$set(_vm.terms, "cancellation", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"terms.cancellation"}})],1),_c('div',{staticClass:"d-flex"})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }